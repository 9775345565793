<app-dialog #dialog
  (cancel)="cancelButtonClicked()"
  (confirm)="addDescriptionButtonClicked()"
  cancelButtonText="Cancel"
  [confirmButtonText]="data.description ? 'Update' : 'Add'"
  [isConfirmButtonEnabled]="!!descriptionFormControl.value"
  [title]="(data.description ? 'Edit' : 'Add') + ' description'">
  <mat-form-field>
    <div *ngIf="fetchingData" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"><mat-spinner diameter="20"></mat-spinner> <span>Fetching description, please wait... </span></div>
    <textarea cdkTextareaAutosize matInput #text [formControl]="descriptionFormControl" maxlength="2000" cdkAutosizeMinRows="14"
      [placeholder]="placeholderText"></textarea>
    <mat-hint align="end">{{text.value.length}} / 2000</mat-hint>
  </mat-form-field>
</app-dialog>
