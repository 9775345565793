import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { take } from 'rxjs/operators';

import { NoteMoment } from './note-moment';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { DeleteNoteMomentDialogComponent } from './delete-note-moment-dialog/delete-note-moment-dialog.component';
import { TimelineService } from '../../timeline.service';
import { ExpandNoteMomentDialogComponent } from './expand-note-moment-dialog/expand-note-moment-dialog.component';

@Component({
  selector: 'app-note-moment',
  templateUrl: './note-moment.component.html',
  styleUrls: ['./note-moment.component.scss']
})
export class NoteMomentComponent {

  momentData: NoteMoment;

  constructor(private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private timelineService: TimelineService
  ) {}

  public deleteButtonClicked(): void {
    const patientId = this.activatedRoute.snapshot.params.patientId;
    const dialog = this.dialogService.openDialog(DeleteNoteMomentDialogComponent, {
      momentId: this.momentData.id,
      patientId: patientId
    });

    dialog.afterClosed().pipe(take(1)).subscribe(() => {
      this.timelineService.updateTimelineWithFilterOptions();
    });
  }

  public expandButtonClicked(): void {
    this.dialogService.openDialog(ExpandNoteMomentDialogComponent, {
      title: `${this.momentData.author}`,
      subtitle: `${this.momentData.timestamp.toDateString()}, ${this.momentData.timestamp.toLocaleTimeString()}`,
      note: this.momentData.note
    }, {large: true});
  }
}
