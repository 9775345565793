import { Component, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { animateText } from '../main-nav-animations';
import { NavLink } from './nav-link';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: [ './nav-link.component.scss' ],
  animations: [animateText],
})
export class NavLinkComponent {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() linkData: NavLink;
  @Input() set mainNavOpenState (state: boolean) {
    setTimeout(() => {
      this.displayLinkTextState = state ? 'show' : 'hide';
      this.mainMenuState = state ? 'menu-open' : 'menu-closed';
    }, 250);
  };

  public displayLinkTextState: string;
  public mainMenuState: string;
}
