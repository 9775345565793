import { Moment } from '../moment';
import { MomentType } from '../moment-type';

export class AuditEventMoment extends Moment {
  public eventTitle: string;
  readonly typeName = MomentType.Event;

  constructor(options: { id: string, timestamp: Date, eventTitle: string, author: string }) {
    super(options.id, options.timestamp, options.author);
    this.eventTitle = options.eventTitle;
  }
}
