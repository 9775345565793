import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CallMoment } from './call-moment';
import { PatientTimelineService } from '@backend-client/services';

@Component({
  selector: 'app-call-moment',
  templateUrl: './call-moment.component.html',
  styleUrls: [ './call-moment.component.scss' ]
})

export class CallMomentComponent {

  momentData: CallMoment;

  constructor(private patientTimelineService: PatientTimelineService,
              private activatedRoute: ActivatedRoute) { }

  public downloadMoment = async (): Promise<void> => {
    // first, acquire the signedUrl needed to actually download the file
    const momentId = this.momentData.id;
    const patientId = this.activatedRoute.snapshot.params.patientId;
    const url = await this.patientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrl({ momentId, patientId }).toPromise();

    // second, actually trigger the download
    window.open(url.signedUrl);
  };
}
