<app-moment svgIcon="google-drive"
            [title]="momentData.fileName"
            [colour]="momentData.contentType === momentType.Call ? '#46cbf3' : '#fdb47f'"
            [momentData]="momentData">
  <div fxFlex fxLayout="column" fxLayoutGap="16px">
    <div *ngIf="momentData?.description">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="6px">
        <div fxFlex class="sub-heading">Description</div>

        <div *ngIf="(featureFlags$ | async)?.isGoogleDriveEnabled">
          <button mat-icon-button aria-label="Edit description" matTooltip="Edit description" (click)="addEditDescription(momentData.description)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Fetch description history" matTooltip="Fetch description history" (click)="fetchAuditHistory()">
            <mat-icon>history</mat-icon>
          </button>
        </div>
      </div>
      <div>
        {{momentData.description}}
      </div>
    </div>
    <div *ngIf="(featureFlags$ | async)?.isGoogleDriveEnabled" fxFlex fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px">
      <button *ngIf="!momentData?.description" mat-button color="primary" (click)="addEditDescription()">Add Description</button>
      <button mat-button (click)="previewMoment()">Preview</button>
      <button *ngIf="showDownloadButton | async" mat-button color="accent" (click)="downloadMoment()">Download</button>
    </div>
  </div>
</app-moment>
