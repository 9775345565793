<app-dialog #dialog
  (cancel)="closeButtonClicked()"
  cancelButtonText="Close"
  title="Description history">
  <div fxLayout="column">
    <div *ngIf="loading; else history" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <mat-spinner diameter="20"></mat-spinner>
      <span>Fetching description, please wait... </span>
    </div>
  </div>
</app-dialog>

<ng-template #history>
  <div class="current">
    <div fxLayout="row" fxLayoutAlign="start center">
      <p fxFlex class="sub-heading">Current description</p>
      <p class="author-timestamp">{{ auditHistory.therapistName }} | {{ auditHistory.ts | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
    </div>
    <p>{{ auditHistory.description }}</p>
  </div>
  <mat-divider></mat-divider>
  <div class="previous">
    <div fxLayout="row" fxLayoutAlign="start center">
      <p fxFlex class="sub-heading">Previous description</p>
      <p *ngIf="auditHistory.lastAuditInfo" class="author-timestamp">{{ auditHistory.lastAuditInfo.therapistName }} | {{ auditHistory.lastAuditInfo.lastUpdateTime | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
    </div>
    <p *ngIf="auditHistory.lastAuditInfo; else nohistory">{{ auditHistory.lastAuditInfo.description }}</p>
  </div>
</ng-template>

<ng-template #nohistory><p> There is no previous history for this description. </p></ng-template>
