<div matTooltip="You do not have permission to add items to the patient timeline"
     [matTooltipDisabled]="'patient:upload-moments' | userHasScope | async">
  <button [disabled]="!('patient:upload-moments' | userHasScope | async)" id="add-moment-button" mat-fab color="primary"
          [matMenuTriggerFor]="menu">
    <mat-icon>
      add
    </mat-icon>
  </button>
  <mat-menu class="add-moment-menu" xPosition="before" yPosition="above" #menu="matMenu">
    <ng-container *ngIf="(featureFlags$ | async)?.isGoogleDriveEnabled; else cloudBucketUpload">
      <button *ngFor="let driveMoment of addGoogleDriveMomentConfig" mat-menu-item
              (click)="addGoogleDriveMomentButtonClicked()">
        <mat-icon>{{driveMoment.icon}}</mat-icon>
        <span>{{driveMoment.title}}</span>
      </button>
    </ng-container>
    <!-- Note moment only -->
    <button *ngFor="let moment of addNoteMomentConfig" mat-menu-item
            (click)="addMomentButtonClicked(moment.dialogComponent, moment.resizable)">
      <mat-icon>{{moment.icon}}</mat-icon>
      <span>{{moment.title}}</span>
    </button>
  </mat-menu>
</div>

<ng-template #cloudBucketUpload>
  <button *ngFor="let cloudMoment of addMomentConfig" mat-menu-item
    (click)="addMomentButtonClicked(cloudMoment.dialogComponent, cloudMoment.resizable)">
    <mat-icon>{{cloudMoment.icon}}</mat-icon>
    <span>{{cloudMoment.title}}</span>
  </button>
</ng-template>
