<div id="filter-bar" fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="12px" class="mat-elevation-z1" [class.display-in-overlay]="displayInOverlay">
  <mat-form-field id="date-picker">
    <input matInput readonly [max]="maxFilterDate" [matDatepicker]="picker" [(ngModel)]="filterOptions.date"
           (dateChange)="dateChange($event)">
    <mat-label>Show Until</mat-label>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <button fxFlex mat-button [matMenuTriggerFor]="types" id="types-selector">
    <div fxFlex class="types-selector-inner" fxLayout="column" fxLayout="start center">
      <div class="select-label">
        <div [ngClass]="{'label' : numberOfActiveFilters > 1, 'trigger-label': numberOfActiveFilters === 0}" fxFlex>Type</div>

        <div *ngIf="numberOfActiveFilters > 1" fxFlex class="selected">
          {{ (displayListOfSelected | convertStringToCamelCase) }}
        </div>
      </div>
    </div>
  </button>

  <mat-menu #types="matMenu">
    <mat-checkbox [(ngModel)]="filterOptions.types.call" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      Call
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="filterOptions.types.note" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      Note
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="filterOptions.types.file" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      File
    </mat-checkbox>
    <mat-checkbox *ngIf="(featureFlagService.featureFlags$ | async)?.isGoogleDriveEnabled"
                  [(ngModel)]="filterOptions.types.googleFile" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      Google File
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="filterOptions.types.chat" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      Chat
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="filterOptions.types.event" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      Event
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="filterOptions.types.discharge" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      Discharge
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="filterOptions.types.cgiScore" (change)="checkboxChange()" (click)="$event.stopPropagation()">
      CGI Score
    </mat-checkbox>
  </mat-menu>

  <button mat-raised-button color="primary" [disabled]="!areFiltersValid" (click)="filterButtonClicked()">Apply</button>
</div>
