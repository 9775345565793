import { Injectable } from '@angular/core';

import { PatientTimelineService } from '@backend-client/services';

@Injectable({
  providedIn: 'root'
})
export class NoteMomentService {

  constructor(private patientTimelineService: PatientTimelineService
  ) {}

  public async deleteNoteMoment(patientId: string, momentId: string): Promise<void> {
    await this.patientTimelineService.PatientsMomentsDeleteNoteMoment({ momentId, patientId }).toPromise();
  }
}
