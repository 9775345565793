<app-dialog #dialog
            (cancel)="cancelButtonClicked()"
            (confirm)="addMomentButtonClicked(cgiSeverity.value, cgiImprovement.value)"
            cancelButtonText="Cancel"
            confirmButtonText="Add"
            [isConfirmButtonEnabled]="!!cgiSeverity.value && !!cgiImprovement.value"
            title="Add CGI Moment">
  <div class="dialog-content" fxLayout="row" fxLayoutGap="12px">
    <mat-form-field fxFlex class="form-group">
      <mat-label for="cgiSeverity">CGI Severity</mat-label>
      <mat-select id="cgiSeverity" #cgiSeverity>
        <mat-option value="">Select</mat-option>
        <mat-option *ngFor="let option of cgiOptionList" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex class="form-group">
      <mat-label for="cgiImprovement">CGI Improvement</mat-label>
      <mat-select id="cgiImprovement" #cgiImprovement>
        <mat-option value="">Select</mat-option>
        <mat-option *ngFor="let option of cgiOptionList" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</app-dialog>
