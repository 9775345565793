import { Moment } from '../moment';
import { MomentType } from '../moment-type';

// TODO: Use Metadata decorator. This will allow data such as whether the moment is addable by a chatSession ('addable'), to be assigned,
//  then used to populate a list that can be rendered in the "Add Moment" dialog box.

// @Metadata({
//   addable: true,
//   addComponent: AddCallMomentDialogComponent,
//   displayMomentComponent: CallMomentComponent
// })

export class CallMoment extends Moment {

  public fileName: string;
  public createdByUserId: number;
  readonly typeName = MomentType.Call;

  constructor(options: { id: string, timestamp: Date, fileName: string, createdByUserId: number, author: string }) {
    super(options.id, options.timestamp, options.author);
    this.fileName = options.fileName;
    this.createdByUserId = options.createdByUserId;
  }
}
