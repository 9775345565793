import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MessageService } from '@shared/services/message.service';
import { TimelineService } from '../timeline.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { MomentType } from '../moment/moment-type';

@Component({
  selector: 'app-add-call-moment',
  templateUrl: './add-call-moment.component.html',
  styleUrls: [ './add-call-moment.component.scss' ]
})
export class AddCallMomentComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  public selectedFile: File;

  constructor(private dialogRef: MatDialogRef<AddCallMomentComponent>,
              private messageService: MessageService,
              @Inject(MAT_DIALOG_DATA) private data: { timelineService: TimelineService, patientId: string },
              private router: Router) { }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async addMomentButtonClicked(): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        await this.data.timelineService.addFileMoment(this.data.patientId, this.selectedFile, MomentType.Call);
        this.data.timelineService.updateTimelineWithFilterOptions();
      });
      this.dialogRef.close();
      // await this.router.navigate([`/therapist/assigned-patients/patient-profile/${this.data.patientId}`]);
    } catch (err) {
      console.error(err);
      this.messageService.showMessage('Error: ' + err.toString());
    }
  }

  public onFileInput(event): void {
    this.selectedFile = event.target.files[0];
  }
}
