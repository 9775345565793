import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MessageService } from '@shared/services/message.service';
import { TimelineService } from '../timeline.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { DashboardTubMoment } from '@backend-client/models';

@Component({
  selector: 'app-add-note-moment',
  templateUrl: './add-note-moment.component.html',
  styleUrls: ['./add-note-moment.component.scss']
})
export class AddNoteMomentComponent implements OnInit {

  @ViewChild('dialog') dialog: DialogComponent;
  public previousNote: DashboardTubMoment;

  constructor(private dialogRef: MatDialogRef<AddNoteMomentComponent>,
              private messageService: MessageService,
              @Inject(MAT_DIALOG_DATA) private data: { timelineService: TimelineService, patientId: string },
              private router: Router
    ) {
  }

  async ngOnInit() {
    this.previousNote = (await this.data.timelineService.getLatestNoteMoment(this.data.patientId))[0];
  }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async addMomentButtonClicked(noteText: string): Promise<void> {
    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        await this.data.timelineService.addNoteMoment(this.data.patientId, noteText);
        this.data.timelineService.updateTimelineWithFilterOptions();
      });
      this.dialogRef.close();
      // await this.router.navigate([ `/therapist/assigned-patients/patient-profile/${this.data.patientId}` ]);
    } catch (err) {
      console.error(err);
      this.messageService.showMessage('Error: ' + err.toString());
    }
  }

}
