import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NoteMomentService } from '../note-moment.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { MessageService } from '@shared/services/message.service';

@Component({
  selector: 'app-delete-note-moment-dialog',
  templateUrl: './delete-note-moment-dialog.component.html',
  styleUrls: ['./delete-note-moment-dialog.component.scss']
})
export class DeleteNoteMomentDialogComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { momentId: string, patientId: string },
              private dialogRef: MatDialogRef<DeleteNoteMomentDialogComponent>,
              private noteMomentService: NoteMomentService,
              private messageService: MessageService) { }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async deleteButtonClicked(): Promise<void> {
    await this.dialog.showAsyncProgressForFunction(async () => {
      try {
        await this.noteMomentService.deleteNoteMoment(this.data.patientId, this.data.momentId);
      } catch (err) {
        this.messageService.showMessage('Error: Note could not be deleted. Please refresh the page and try again.');
      }
      this.dialogRef.close();
    });
  }

}
