import { Moment, MomentLinkConnectionType } from '../moment';
import { ChatType } from './chat-type.enum';
import { MomentType } from '../moment-type';

export interface ChatMessage {
  user: {
    id: string;
    role: string;
    displayName?: string;
  };
  message: string;
  timeSent: Date;
}

export class ChatMoment extends Moment {
  public chatType: ChatType;
  public chatSessionId: string;
  public chatSessionArchive?: { [key: string]: ChatMessage };
  public pubNub: boolean;
  readonly typeName = MomentType.Chat;

  public get displayLinkConnection(): MomentLinkConnectionType {
    return this.chatType === ChatType.StartChat ? MomentLinkConnectionType.StartLink : MomentLinkConnectionType.EndLink;
  }

  constructor(options: {
    id: string;
    chatSessionId: string;
    timestamp: Date;
    author: string,
    chatType: ChatType;
    chatSessionArchive?: { [key: string]: ChatMessage }
    pubNub: boolean;
  }) {
    super(options.id, options.timestamp, options.author);
    this.chatType = options.chatType;
    this.chatSessionId = options.chatSessionId;
    this.chatSessionArchive = options.chatSessionArchive;
    this.pubNub = options.pubNub;
  }
}
