import { Component } from '@angular/core';
import { CgiScoreMoment } from './cgi-score-moment';

@Component({
  selector: 'app-cgi-score-moment',
  templateUrl: './cgi-score-moment.component.html',
  styleUrls: ['./cgi-score-moment.component.scss'],
})
export class CgiScoreMomentComponent {
  public momentData: CgiScoreMoment;
}
