import { Moment } from '../moment';
import { MomentType } from '../moment-type';

export class FileMoment extends Moment {

  public fileName: string;
  public createdByUserId: number;
  readonly typeName = MomentType.File;

  constructor(options: { id: string, timestamp: Date, fileName: string, createdByUserId: number, author: string }) {
    super(options.id, options.timestamp, options.author);
    this.fileName = options.fileName;
    this.createdByUserId = options.createdByUserId;
  }
}
