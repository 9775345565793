<app-dialog #dialog
  (cancel)="cancelButtonClicked()"
  (confirm)="addMomentButtonClicked()"
  cancelButtonText="Cancel"
  confirmButtonText="Add"
  [isConfirmButtonEnabled]="!!selectedFile"
  title="Add a file">
  <div fxFlex="grow" fxLayout="column" fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="grow" [ngClass]="{'placeholder-file-name': !selectedFile}">
        {{selectedFile?.name || 'file-name.txt'}}
      </div>
      <button mat-mini-fab (click)="callInput.click()">
        <mat-icon>attach_file</mat-icon>
        <input #callInput type="file" (change)="onFileInput($event)"/>
      </button>
    </div>
  </div>
</app-dialog>
