import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { MediaObserver } from '@ngbracket/ngx-layout';

import { TimelineFilterOptions } from './timeline-filter-options';
import { FeatureFlagService } from '@shared/services/feature-flag.service';

@Component({
  selector: 'app-timeline-filters',
  templateUrl: './timeline-filters.component.html',
  styleUrls: [ './timeline-filters.component.scss' ]
})
export class TimelineFiltersComponent implements OnInit {

  public maxFilterDate: Date;
  public areFiltersValid = true;

  @Input() displayInOverlay: boolean = false;
  @Output() currentFilterOptions = new EventEmitter<TimelineFilterOptions>();

  public filterOptions: TimelineFilterOptions = {
    types: {
      note: true,
      call: true,
      file: true,
      chat: true,
      googleFile: true,
      event: true,
      discharge: true,
      cgiScore: true
    },
    date: new Date()
  };

  public get displayListOfSelected(): Array<string> {
    if (!this.filterOptions.types) return undefined;

    const selectedTypes = [];
    Object.values(this.filterOptions.types).forEach((value, index) => value ? selectedTypes.push(Object.keys(this.filterOptions.types)[index]) : undefined);
    return selectedTypes;
  }

  public get numberOfActiveFilters(): number {
    if (!this.filterOptions.types) return 0;

    let counter = 0;
    Object.values(this.filterOptions.types).forEach(value => value ? counter++ : null);
    // Returns count for how many are true
    return counter;
  }

  public checkboxChange(): void {
    // Sets areFiltersValid to true if there is at least one filter option selected, otherwise sets it to false
    this.areFiltersValid = Object.values(this.filterOptions.types).some(value => value);
  }

  public setDateToEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 59, 999));
  }

  constructor(public mediaObserver: MediaObserver,
              public featureFlagService: FeatureFlagService) { }

  public ngOnInit(): void {
    this.maxFilterDate = this.setDateToEndOfDay(new Date());
    this.filterOptions.date = this.setDateToEndOfDay(this.filterOptions.date);
    this.currentFilterOptions.emit(this.filterOptions);
  }

  public dateChange($event: MatDatepickerInputEvent<Date>): void {
    this.setDateToEndOfDay($event.value);
  }

  public filterButtonClicked(): void {
    this.currentFilterOptions.emit(this.filterOptions);
  }
}
