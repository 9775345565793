import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FileMoment } from './file-moment';
import { PatientTimelineService } from '@backend-client/services/patient-timeline.service';

@Component({
  selector: 'app-file-moment',
  templateUrl: './file-moment.component.html',
  styleUrls: [ './file-moment.component.scss' ]
})
export class FileMomentComponent {

  momentData: FileMoment;

  constructor(private patientTimelineService: PatientTimelineService,
              private activatedRoute: ActivatedRoute) { }

  public downloadMoment = async (): Promise<void> => {
    // first, acquire the signedUrl needed to actually download the file
    const momentId = this.momentData.id;
    const patientId = this.activatedRoute.snapshot.params.patientId;
    const url = await this.patientTimelineService.PatientsMomentsGetPatientFileContentDownloadUrl({ momentId, patientId }).toPromise();

    // second, actually trigger the download
    window.open(url.signedUrl);
  };
}
