import { Injectable } from '@angular/core';

import { EnvironmentService } from '../environment.service';
import { DialogService } from '../../components/dialog/dialog.service';
import { GoogleDriveFileMoment } from '@shared/components/patient-timeline/moment/google-drive-file-moment/google-drive-file-moment';
import { PreviewFileDialogComponent } from '@shared/components/patient-timeline/preview-file-dialog/preview-file-dialog.component';

@Injectable({
  providedIn: 'root'
})

export class GoogleDrivePreviewService {
  public PREVIEW_SCOPES = this.environmentService.getEnvironment().google.previewScope;
  public googleFileMoment: GoogleDriveFileMoment;

  constructor(
    private environmentService: EnvironmentService,
    private dialogService: DialogService
  ) {}

  public openPreviewDialog(): void {
    this.dialogService.openDialog(PreviewFileDialogComponent, this.googleFileMoment, { large: true });
    this.googleFileMoment = undefined;
  }
}
