<app-dialog #dialog
  (cancel)="cancelButtonClicked()"
  (confirm)="addMomentButtonClicked(text.value)"
  cancelButtonText="Cancel"
  confirmButtonText="Add"
  [isConfirmButtonEnabled]="!!text.value"
  title="Add note">

  <mat-accordion *ngIf="previousNote" class="previous-note">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Previous note
        </mat-panel-title>
        <mat-panel-description class="previous-note-description">
          {{ previousNote?.author }} | {{ previousNote?.ts | date }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="previous-note-content"><p [innerText]="previousNote?.data?.note"></p></div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-form-field class="new-note-entry">
    <textarea cdkTextareaAutosize #text matInput cdkAutosizeMinRows="14"
      placeholder="Enter your note here..."></textarea>
  </mat-form-field>
</app-dialog>
