<app-moment icon="playlist_add_check" [title]="momentData.titleText" colour="#E3B23C" [momentData]='momentData'>
  <ng-container *ngIf="momentData.GADQuestions.length > 0">
    <h1>
      GAD - {{ momentData.GADSeverity }} ({{ momentData.GADScore }})
    </h1>
    <p>{{ RiskAssessmentIntroText }}</p>
    <ul>
      <li *ngFor="let question of momentData.GADQuestions">
        {{ question.question }} - <i>{{ question.answer }}</i>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="momentData.PHQQuestions.length > 0">
    <h1>
      PHQ - {{ momentData.PHQSeverity }} ({{ momentData.PHQScore }})
    </h1>
    <p>{{ RiskAssessmentIntroText }}</p>
    <ul>
      <li *ngFor="let question of momentData.PHQQuestions">
        {{ question.question }} - <i>{{ question.answer }}</i>
      </li>
    </ul>
  </ng-container>
</app-moment>
