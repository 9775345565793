import { Component } from '@angular/core';
import { AuditEventMoment } from './audit-event-moment';

@Component({
  selector: 'app-audit-event-moment',
  templateUrl: './audit-event-moment.component.html',
  styleUrls: ['./audit-event-moment.component.scss']
})
export class AuditEventMomentComponent {

  momentData: AuditEventMoment;

  constructor() { }

}
