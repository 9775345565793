import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { GoogleDriveService } from '@shared/services/google-drive/google-drive.service';
import { TimelineService } from '../timeline.service';
import { AddCallMomentComponent } from '../add-call-moment/add-call-moment.component';
import { AddFileMomentComponent } from '../add-file-moment/add-file-moment.component';
import { AddNoteMomentComponent } from '../add-note-moment/add-note-moment.component';
import { AddCgiMomentComponent } from '../add-cgi-moment/add-cgi-moment.component';

@Component({
  selector: 'app-add-moment-button',
  templateUrl: './add-moment-button.component.html',
  styleUrls: ['./add-moment-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddMomentButtonComponent {
  @Input() patientId: string;

  public addNoteMomentConfig = [
    {
      title: 'Add Note',
      icon: 'assignment',
      resizable: true,
      dialogComponent: AddNoteMomentComponent,
    },
    {
      title: 'Add CGI Report',
      icon: 'assessment',
      resizable: false,
      dialogComponent: AddCgiMomentComponent,
    },
  ];

  public addMomentConfig = [
    {
      title: 'Add Call',
      icon: 'call',
      resizable: false,
      dialogComponent: AddCallMomentComponent,
    },
    {
      title: 'Add File',
      icon: 'save',
      resizable: false,
      dialogComponent: AddFileMomentComponent,
    },
  ];

  public addGoogleDriveMomentConfig = [
    {
      title: 'Add File',
      icon: 'upload_file',
    },
  ];

  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private featureFlagService: FeatureFlagService,
    private googleDriveService: GoogleDriveService,
  ) {}

  public addMomentButtonClicked<T>(dialogComponent: T, resizable: boolean = false): void {
    this.dialogService.openDialog(
      dialogComponent,
      { timelineService: this.timelineService, patientId: this.patientId },
      { large: resizable },
    );
  }

  public async addGoogleDriveMomentButtonClicked(): Promise<void> {
    await this.googleDriveService.uploadFile(this.patientId);
  }
}
