import { Injectable } from '@angular/core';
import { MomentComponentConstructor, MomentConstructor } from './moment-display.decorator';
import { Moment } from './moment';
import { ChatMoment } from './chat-moment/chat-moment';
import { ChatMomentComponent } from './chat-moment/chat-moment.component';
import { CallMoment } from './call-moment/call-moment';
import { FileMoment } from './file-moment/file-moment';
import { GoogleDriveFileMoment } from './google-drive-file-moment/google-drive-file-moment';
import { CallMomentComponent } from './call-moment/call-moment.component';
import { FileMomentComponent } from './file-moment/file-moment.component';
import { GoogleDriveFileMomentComponent } from './google-drive-file-moment/google-drive-file-moment.component';
import { NoteMoment } from './note-moment/note-moment';
import { NoteMomentComponent } from './note-moment/note-moment.component';
import { AuditEventMoment } from './audit-event-moment/audit-event-moment';
import { AuditEventMomentComponent } from './audit-event-moment/audit-event-moment.component';
import { RiskAssessmentEventMoment } from '../moment/risk-assessment-event-moment/risk-assessment-event-moment';
import { RiskAssessmentEventMomentComponent } from '../moment/risk-assessment-event-moment/risk-assessment-event-moment.component';
import { DischargeMomentComponent } from '../moment/discharge-moment/discharge-moment.component';
import { DischargeMoment } from '../moment/discharge-moment/discharge-moment';
import { CgiScoreMoment } from '../moment/cgi-score/cgi-score-moment';
import { CgiScoreMomentComponent } from '../moment/cgi-score/cgi-score-moment.component';

@Injectable({
  providedIn: 'root',
})
export class MomentDisplayService {
  private static momentDisplayComponents = new Map<MomentConstructor, MomentComponentConstructor>(
    [
      [ChatMoment, ChatMomentComponent],
      [CallMoment, CallMomentComponent],
      [NoteMoment, NoteMomentComponent],
      [FileMoment, FileMomentComponent],
      [GoogleDriveFileMoment, GoogleDriveFileMomentComponent],
      [AuditEventMoment, AuditEventMomentComponent],
      [RiskAssessmentEventMoment, RiskAssessmentEventMomentComponent],
      [DischargeMoment, DischargeMomentComponent],
      [CgiScoreMoment, CgiScoreMomentComponent],
    ],
  );

  /**
   * Register a given moment data type to the moment component type that should be used to represent it.
   * NOTE: prefer the @MomentDisplay decorator rather than calling this directly.
   * @param momentDataType type of moment data
   * @param momentComponentType type of angular component to render it
   * @see MomentDisplay decorator
   */
  static registerMomentDisplay(momentDataType: MomentConstructor, momentComponentType: MomentComponentConstructor) {
    this.momentDisplayComponents.set(momentDataType, momentComponentType);
  }

  /**
   * Retrieve the constructor for the MomentComponent that should be used to render the provided moment data.
   * @param moment data for which to determine the required MomentComponent constructor
   */
  public getMomentComponentForData(moment: Moment): MomentComponentConstructor {
    let outputMomentComponentType: MomentComponentConstructor = null;

    // iterate over the momentDisplayComponents Map
    MomentDisplayService.momentDisplayComponents.forEach((momentComponentType, momentDataType) => {
      // if the moment provided is an instance of the current momentDataType, we have found the correct momentComponentType
      if (moment instanceof momentDataType) {
        outputMomentComponentType = momentComponentType;
      }
    });

    return outputMomentComponentType;
  }
}
