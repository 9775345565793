// go-chat-duplication
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'goTotalUnreadMessages'
})
export class GoTotalUnreadMessagesPipe implements PipeTransform {

  transform(messageNotifications: any[]): any {
    console.log(messageNotifications);
    if (messageNotifications.length > 0) {
      const newMessageCount: number[] = messageNotifications.map(messageNotification => messageNotification.unreadMessageCount);
      return newMessageCount.reduce((a, b) => a + b);
    }

  }

}
