import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatestWith, firstValueFrom, groupBy, Subject } from 'rxjs';
import { filter, mergeMap, take, throttleTime } from 'rxjs/operators';

/* Protobuf imports */
/* Helpers */
import {
  ChatMessageReply,
  CommandChatAccept,
  CommandChatAcceptRequest,
  CommandChatBegin,
  CommandChatDeleteMessage,
  CommandChatDischarge,
  CommandChatEditMessage,
  CommandChatLeave,
  CommandChatLostFocus,
  CommandChatMessageAddAction,
  CommandChatMessageRemoveAction,
  CommandChatMessages,
  CommandChatRestoreFocus,
  CommandChatSendMessage,
  CommandChatSetActiveChannel,
  CommandChatSetLastRead,
  CommandChatTherapistInvite,
  CommandChatTypingOff,
  CommandChatTypingOn,
  GoCoreMediator,
  Message,
  PubNubChannels,
  PubNubChatResponse,
  PubNubChatStatus,
  PubNubLastRead,
  PubNubMessages,
  PubNubTyping,
  Response,
  ResponseError,
} from '@thrivesoft/gocore-web';

/* Services */
import { Completer } from '../utils/completer';
import { MessageService } from '../../message.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { TherapistChatService } from '@backend-client/services/therapist-chat.service';
import { GoCoreObserverRepository } from '@shared/services/gocore/gocore-observer-repository';
import { ObserverRepository } from '@shared/services/gocore/observer-repository';
import { TubErrorReportingService } from '@shared/services/tub-error-reporting.service';

/* Models */
import {
  ChatActiveChannelModel,
  ChatChannelModel,
  ChatClient,
  ChatClientUserModel,
  ChatMessageModel,
  ChatReplyModel,
  ChatUserModel,
} from './model';
import { CommandName, GoErrorData } from '../model';
import { TubChatUserPresenceInfo } from '@backend-client/models/tub-chat-user-presence-info';

/* Decorators */
import { ExecuteOnUpdate } from '@shared/services/gocore/chat/decorators/execute-on-update.decorator';
import { ExecuteIfInitialised } from '@shared/services/gocore/chat/decorators/execute-if-initialised.decorator';
import { GoActiveChatSessionsService } from '@app/modules/therapist/go-chat/go-active-chat-sessions.service';
import { GoDashboardTubChatSession } from '@app/modules/therapist/go-chat/go-classes/go-dashboard-tub-chat-session';

/* Values that indicate ready and online */
const INITIALISED = BigInt(64);
const READY = BigInt(2);
const ONLINE = BigInt(4);

@Injectable({
  providedIn: 'root',
})
export class GoCoreChatService {
  // State Management
  public chatClient: ChatClient;
  private goCoreMediator: GoCoreMediator;
  private chatClientUserModel: ChatClientUserModel;
  private fcmToken: string;
  private observerRepository: ObserverRepository;

  // Observables
  public readonly messages$: BehaviorSubject<ChatMessageModel[]> = new BehaviorSubject<ChatMessageModel[]>([]);
  public readonly activeChannels$: Subject<ChatChannelModel[]> = new Subject<ChatChannelModel[]>();
  public readonly typingIndicator$: BehaviorSubject<ChatUserModel[]> = new BehaviorSubject([]);
  public readonly activeChannel$: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly activeTherapists$: BehaviorSubject<Array<TubChatUserPresenceInfo>> = new BehaviorSubject([]);
  public readonly lastAcceptedRequest$: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly goCoreErrorDisconnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly errorSettingActiveChannel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly errorFailedToLoadChatHistory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private readonly error$: Subject<GoErrorData> = new Subject<GoErrorData>();

  // Loading Status
  public readonly chatLoadState$: BehaviorSubject<CommandName> = new BehaviorSubject(null);
  public initialised$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Status
  public readonly therapistLeaveFailed$: Subject<string> = new Subject<string>();
  public readonly therapistLeftSuccessfully$: Subject<void> = new Subject<void>();
  public readonly dischargeSuccessful$: Subject<void> = new Subject<void>();
  public readonly dischargeFailed$: Subject<string> = new Subject<string>();

  public loadingMessages$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private tubChatChannelsCount$: Subject<number> = new Subject<number>();
  private pubNubChatChannels$: Subject<ChatChannelModel[]> = new Subject<ChatChannelModel[]>();

  constructor(
    private therapistChatService: TherapistChatService,
    private messageService: MessageService,
    private environmentService: EnvironmentService,
    private tubErrorReportingService: TubErrorReportingService,
    private activeChatSessionsService: GoActiveChatSessionsService
  ) {
    this.chatClient = new ChatClient();
    this.setupErrorReporting();

    this.initialised$
      .pipe(
        filter(isInitialised => isInitialised === true),
        take(1))
      .subscribe( initialised => {
      if (initialised) {
        // No need to await - this configures an observable that emits when resolved
        this.setChatChannelCountOnTub();
        this.setActiveChatChannels();
      }
    });
  }

  /**
   * Initialises all the observers to handle all the relevant chat commands that come out of GoCore
   */
  private initObservers(): void {
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatChannelsProperty,
      this.updateGoCoreChannels.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatTypingProperty,
      this.setTypingIndicatorForOthers.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatMessagesProperty,
      this.onChatMessagesObserverResponse.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatStatusProperty,
      this.setGoChatInitialisationStatus.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatLastReadProperty,
      this.setLastReadResponse.bind(this),
    );
    this.observerRepository.observeProperty(
      GoCoreObserverRepository.opChatErrorProperty,
      this.onChatErrorObserverResponse.bind(this),
    );
  }

  /**
   * Retrieves the number of chat channels from TUB - this is considered the source of truth when initialising the dashboard, and used
   * in calculating when GoCore has finished loading the chat channels by comparing these values.
   */
  private async setChatChannelCountOnTub(): Promise<void> {
    const tubChatChannels: GoDashboardTubChatSession[] = await this.activeChatSessionsService.getActiveChatSessions();
      this.tubChatChannelsCount$.next(tubChatChannels.length);
  }

  /**
   * When GoCore outputs the correct number of channels, the data is incomplete.
   * This value determines how many emissions of the correct number of channels to skip before considering the data complete.
   */
  private goCoreSkipValue = 1;

  /**
   * Takes the output from TUB, and GoCore and compares the number of channels so that we know when GoCore has finished loading them all.
   * Outputs the value via activeChannels$ as the single source of truth for the chat data.
   */
  private setActiveChatChannels(): void {
    this.tubChatChannelsCount$
      .pipe(
        combineLatestWith(this.pubNubChatChannels$)
      )
      .subscribe(([tubCount, pubNubChannels]): void => {
        // If TUB says there are no chat channels, then instantly set the goCoreSkipValue to 0 so activeChannels$ can be updated as soon
        // as possible
        if (tubCount === 0) {
          this.goCoreSkipValue = 0;
        }
        // If the number of channels is equal to the number of channels from PubNub, then continue. The goCoreSkipValue is there to
        // short circuit this statement in the case where the PubNub channel length does not match the number of TUB channels. This
        // would happen if the therapist accepts or discharges patients during their session.
        if (tubCount === pubNubChannels.length || this.goCoreSkipValue === 0) {
          if (this.goCoreSkipValue > 0) {
            this.goCoreSkipValue--;
          } else {
            this.chatClient.channels = pubNubChannels;
            this.activeChannels$.next(pubNubChannels);
          }
        }
      });
  }

  /**
   * Handles the response from the chat channels observer.
   * @param response The response from GoCore
   */
  @ExecuteOnUpdate()
  private updateGoCoreChannels(response: Response): void {
    const channels = PubNubChannels.fromBinary(response.body).channels;
    const convertedChannels = ChatChannelModel.convertToChannelList(channels);
    this.pubNubChatChannels$.next(convertedChannels);
  }

  public async restoreChatFocus(): Promise<void> {
    await this.command(new CommandChatRestoreFocus());
  }

  public async lostChatFocus(): Promise<void> {
    await this.command(new CommandChatLostFocus());
  }

  /**
   * TYPING
   **/

  /**
   * Sets the "currently typing" state for the other users in the current chat
   * @param response The response from GoCore
   */
  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private setTypingIndicatorForOthers(response: Response): void {
    const typingData: PubNubTyping = PubNubTyping.fromBinary(response.body);

    this.chatClient.typingNow = typingData.typingNow.map((uuid: string) => {
      return {
        uuid,
        role: this.getMessageOwner(uuid),
      };
    });

    const otherUsersCurrentlyTyping = this.chatClient.typingNow.filter((user: ChatUserModel) => {
        return user.uuid !== this.chatClientUserModel.id;
    });

    this.typingIndicator$.next(otherUsersCurrentlyTyping);
  }

  /**
   * Clears typing indicator for users
   * @param therapistOnly Parameter to specify only the therapist's typing indicator should be cleared
   */
  private clearTypingIndicators(therapistOnly: boolean = false) {
    if (therapistOnly) {
      const therapistTyping = this.chatClient.typingNow.filter(u => u.uuid === this.chatClientUserModel.id);

      if (therapistTyping.length > 0) {
        this.chatClient.typingNow = this.chatClient.typingNow.filter(u => u.uuid !== this.chatClientUserModel.id);
        this.command(new CommandChatTypingOff());
      }
    } else {
      this.typingIndicator$.next([]);
    }
  }

  /**
   * Informs GoCore when the therapist is currently typing
   */
  public setTypingIndicatorForTherapist() {
    if (!this.chatClient.typingNow.find(u => u.uuid === this.chatClientUserModel.id)) {
      this.chatClient.typingNow.push({ uuid: this.chatClientUserModel.id, role: 'You' });
    }
    this.command(new CommandChatTypingOn());
  }

  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private onChatMessagesObserverResponse(response: Response): void {
    const decoded = PubNubMessages.fromBinary(response.body);
    const messages = ChatMessageModel.messages(decoded.messages);
    this.clearTypingIndicators();
    this.setMessagesDiff(messages);
    this.loadingMessages$.next(false);
  }

  /**
   * Set the initialisation status to true once the chat service becomes ready and online.
   * @param response The response from GoCore
   */
  @ExecuteOnUpdate()
  private setGoChatInitialisationStatus(response: Response): void {
    const chatStatus: PubNubChatStatus = PubNubChatStatus.fromBinary(response.body);

    const isReadyAndOnline: boolean = chatStatus.state === INITIALISED + READY + ONLINE;
    const isNotInitialised: boolean = !this.initialised$.value;

    if (isReadyAndOnline && isNotInitialised) {
      this.initialised$.next(true);
    }
  }

  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private setLastReadResponse(response: Response): void {
    const lastReadResponse: PubNubLastRead = PubNubLastRead.fromBinary(response.body);

    // TODO: sometimes this.chatClientUserModel.id is undefined when this line gets triggered - race condition?
    if (lastReadResponse.userID !== this.chatClientUserModel?.id) {
      let channelResponses = this.chatClient.opponentsActivity?.get(lastReadResponse.channel) || [];

      if (channelResponses?.length > 0) {
        channelResponses = channelResponses.filter(a => a.userID !== lastReadResponse.userID);
      }

      channelResponses.push(lastReadResponse);
      this.chatClient.opponentsActivity.set(lastReadResponse.channel, channelResponses);
      const patientUserId = this.getPatientUserId();

      if (patientUserId) {
        const patientActivity = channelResponses.filter(a => a.userID === patientUserId);
        const responseDate = lastReadResponse?.Time ? this.convertEpochDate(lastReadResponse?.Time) : null;
        const patientLastSeenTime = patientActivity[0]?.Time ? this.convertEpochDate(patientActivity[0].Time) : null;

        if (
          (lastReadResponse.userID === patientUserId && patientActivity.length === 0) ||
          (lastReadResponse.userID === patientUserId &&
            patientActivity.length > 0 &&
            patientLastSeenTime >= responseDate)
        ) {
          this.setMessages(this.chatClient.messages);
        }
      }
    }
  }

  @ExecuteOnUpdate()
  @ExecuteIfInitialised()
  private onChatErrorObserverResponse(response: Response): void {

    // If there is no properly constructed message from GoCore, then ignore it and return - there's nothing we can do.
    if(!response?.error?.Message) {
      return;
    }

    if (response.error.Message.includes('NOT_IN_CHAT_WITH_PATIENT')) {
      this.messageService.showMessage(
        'Either you have left the chat, or access to this chat has been revoked by an administrator.',
      );
      return;
    }

    if (response.error.Message.includes('START_CHAT_MOMENT_EXISTS_BUT_NOT_RTD_CHAT_ROOM')) {
      this.messageService.showMessage(
        'Failed to load chat history, found chat start moment but no data exists in the realtime database.',
      );
      return;
    }

    if (response.error.Message === CommandName.CHAT_SUMMARY_NOT_FOUND) {
      console.error(`Chat summary details failed to return ${this.activeChannel$.value}`);
      return;
    }

    this.messageService.showMessage(`Error: An unknown error occurred`);
  }

  public async initialise(gocore: GoCoreMediator, observerRepository: ObserverRepository): Promise<void> {
    this.goCoreMediator = gocore;
    this.observerRepository = observerRepository;
    this.fcmToken = this.environmentService.getEnvironment().gocore.fcmToken;
    this.initObservers();
    await this.initChat();
  }

  public async initChat(): Promise<void> {
    try {
      await this.command(new CommandChatBegin());
    } catch (e) {
      console.log('[ERR] ::', e);
    }
  }

  public async sendChat(text: string, replyId?: bigint, replyText?: string, replyAuthor?: string): Promise<void> {
    this.clearTypingIndicators(true);
    const msg: CommandChatSendMessage = new CommandChatSendMessage({ message: text });
    if (replyId) {
      msg.reply = new ChatMessageReply({
        messageId: replyId.toString(),
        messageText: replyText,
        messageAuthorId: replyAuthor,
      });
    }
    await this.command(msg);
    await this.command(new CommandChatTypingOff());
  }

  public async editChat(id: bigint, text: string): Promise<void> {
    await this.command(
      new CommandChatEditMessage({
        messageId: id.toString(),
        messageText: text,
      }),
    );
  }

  public async deleteChat(id: bigint): Promise<void> {
    await this.command(new CommandChatDeleteMessage({ messageId: id.toString() }));
  }

  public async messageReact(id: bigint, uuid?: string): Promise<void> {
    if (!uuid) {
      await this.command(
        new CommandChatMessageAddAction({
          MessageId: id.toString(),
          ActionType: 'liked',
        }),
      );
    } else {
      await this.command(
        new CommandChatMessageRemoveAction({
          MessageId: id.toString(),
          ActionId: uuid,
        }),
      );
    }
  }

  /**
   * Group errors by CommandName, and report each stream of errors with an individual rate limit.
   * This will prevent the reporting endpoint from being spammed, while still capturing different errors that may occur.
   */
  private setupErrorReporting(): void {
    // One minute
    const RATE_LIMIT = 60 * 1000;

    this.error$
      .pipe(
        groupBy(err => err.commandName),
        mergeMap(group => group.pipe(throttleTime(RATE_LIMIT))),
      )
      .subscribe(err => this.reportError(err));
  }

  private reportError(err: GoErrorData): void {
    err.data.reporter = 'DASHBOARD_REPORTER';
    this.tubErrorReportingService.send(err.commandName, null, err.data);
  }

  public retryLoadSession() {
    setTimeout(() => {
      switch (this.chatLoadState$.value) {
        case null:
          this.setActiveChannel(this.activeChannel$.value);
          break;
        case CommandName.SET_ACTIVE_CHANNEL:
          this.messageService.showMessage('Retrying to fetch messages... please wait.');
          this.loadMoreMessages();
          break;
      }
    }, 2000);
  }

  private subscribeOnlineStatus(): void {
    if (!this.messageService.isOnline$.value) {
      const sub = this.messageService.isOnline$.subscribe(isOnline => {
        if (isOnline) {
          if (!this.initialised$.value) {
            // this.initUser();

            if (sub) {
              sub?.unsubscribe();
            }
          }

          switch (this.chatLoadState$.value) {
            case null:
              this.setActiveChannel(this.activeChannel$.value);

              if (sub) {
                sub?.unsubscribe();
              }
              break;
            case CommandName.SET_ACTIVE_CHANNEL:
              this.messageService.showMessage('Retrying to fetch messages... please wait.');
              this.loadMoreMessages();

              if (sub) {
                sub?.unsubscribe();
              }
              break;
          }
        }
      });
    }
  }

  private async command(message: Message): Promise<Message> {
    const task: Completer<Message> = new Completer<Message>();

    try {
      this.goCoreMediator.sendCommand(message, (response: Response) => {
        task.complete(response);
      });
    } catch (err) {
      this.goCoreErrorDisconnected$.next(true);
    }

    return task.promise;
  }

  public async setActiveChannel(name: string): Promise<void> {
    this.loading$.next(true);
    this.chatLoadState$.next(null);
    this.errorSettingActiveChannel$.next(false);
    this.errorFailedToLoadChatHistory$.next(false);

    const channelString = 'PatientTherapistChat.';

    if (name.includes(channelString)) {
      name = name.replace(channelString, '');
    }

    this.loadChatParticipants(name);
    const concatName = `${channelString}${name}`;
    this.chatClient.channelToActivate = concatName;

    try {
      const res: Response = (await this.command(new CommandChatSetActiveChannel({ ChatId: concatName }))) as Response;
      const resParsed: CommandChatSetActiveChannel = CommandChatSetActiveChannel.fromBinary(res.body);

      this.chatLoadState$.next(CommandName.CHAT_MORE_MESSAGES);
      this.setChatVariables(resParsed.chatResponse);

      this.subscribeOnlineStatus();

      this.clearTypingIndicators(true);
      this.loadingMessages$.next(false);
    } catch (e) {
      console.error(
        `Failed to set active channel ${this.activeChannel$.value} ${e.error?.Message ? '- ' + e.error.Message : ''}`,
      );
      this.errorSettingActiveChannel$.next(true);
      this.subscribeOnlineStatus();
    }
  }

  public getUser(): ChatClientUserModel {
    return this.chatClientUserModel;
  }

  private setUser(user: ChatClientUserModel) {
    this.chatClientUserModel = user;
  }

  private getPatientUserId(): string {
    return this.chatClient.channelUsers.filter(u => u.role === 'patient')[0]?.userId;
  }

  private getPatientId(): string {
    return this.chatClient.channelUsers.filter(u => u.role === 'patient')[0]?.patientId;
  }

  public async loadChatParticipants(activeChannelId: string) {
    if (activeChannelId == null) {
      console.error('Failed to parse active channel ID to fetch chat participants from TUB.');
      return;
    }

    try {
      this.chatClient.channelUsers = await firstValueFrom(
        this.therapistChatService.TherapistChatsControllerV2GetChatParticipants(activeChannelId),
      );
      this.activeTherapists$.next(this.chatClient.channelUsers.filter(user => user.role === 'therapist'));
    } catch (error) {
      console.error('Error loading chat participants:', error);
      this.messageService.showMessage('Error: Failed to load chat participants');
    }
  }

  private setChatVariables(response: PubNubChatResponse): void {
    this.setUser(response.chatUser);

    this.chatClient.activeUsers = ChatActiveChannelModel.list(response.activeUsers.Channels) ?? [];

    // Sometimes the response does not contain a messages property, so optional chaining is mandatory
    this.chatClient.messages = ChatMessageModel.messages(response?.messages?.messages) ?? [];
    this.chatClient.activeChannel = response.activeChannel === '' ? null : response.activeChannel;

    if (this.chatClient.messages.length === 0) {
      this.loading$.next(false);
    }
    // TODO - we need to ensure messages are updated, this can happen in several gocore response channels
    // setting them here covers these cases but should be more explicitly set in the correct response channel
    // Examples where messages need explicitly set: setLastRead, setActiveChannel, chatAccept
    this.setMessages(this.chatClient.messages);
    this.loading$.next(false);
  }

  public async onInviteTherapist(selectedTherapistId: string, text: string): Promise<boolean> {
    const task = new Completer<boolean>();
    const user = this.getUser();

    if (user?.therapist !== null && user?.therapist === false) {
      task.complete(true);
      return;
    }

    await this.chatTherapistInvite(this.getPatientId(), selectedTherapistId, text).then(() => {
      task.complete(true);
    });
    return task.promise;
  }

  private async chatTherapistInvite(patientId: string, therapistId: string, message: string): Promise<void> {
    try {
      await this.command(
        new CommandChatTherapistInvite({
          PatientId: patientId,
          TherapistId: therapistId,
          Message: message,
        }),
      );

      this.messageService.showMessage('Invitation has been successfully sent.');
    } catch (e) {
      const error: ResponseError = (e as Response).error;
      if (error.Message.includes('ActionPendingError')) {
        this.messageService.showMessageAndClose(
          'Invite to chat failed. Therapist(s) already have a pending invitation.',
        );
        console.error(error.Message);
      } else if (error.Message.includes('ConflictError')) {
        this.messageService.showMessageAndClose(
          'Invite to chat failed. Invited therapist already has access to the chat.',
        );
        console.error(error.Message);
      } else {
        this.messageService.showMessageAndClose('Invite to chat failed. Please retry later.');
      }
    }
    return;
  }

  public async chatAcceptRequestTherapist(requestId: string): Promise<{ chatRoomId: string }> {
    try {
      const response = (await this.command(
        new CommandChatAcceptRequest({
          RequestID: requestId,
        }),
      )) as Response;
      const chatResponse: CommandChatAcceptRequest = CommandChatAcceptRequest.fromBinary(response.body);

      this.lastAcceptedRequest$.next(chatResponse.chatRoom.ChatRoomId);
      return await this.acceptChatRequestTherapist(chatResponse.chatRoom.ChatRoomId);
    } catch (e) {
      const error: ResponseError = e as ResponseError;

      this.messageService.showMessage(
        error.Message.includes('NotFoundError')
          ? 'Accept chat request failed. Request has been accepted by another Therapist.'
          : 'Accept chat request failed. Please retry later.',
      );
    }

    return;
  }

  public async acceptChatRequestTherapist(chatRoomId: string): Promise<{ chatRoomId: string }> {
    if (chatRoomId !== null) {
      try {
        const response = (await this.command(new CommandChatAccept({ ChatId: chatRoomId }))) as Response;

        const chat: CommandChatAccept = CommandChatAccept.fromBinary(response.body);

        if (chat.chatResponse) {
          return { chatRoomId: chatRoomId };
        }
      } catch (e) {}
    }
  }

  public async chatTherapistLeave(channelId: string, patientId: string) {
    try {
      // Caution: This command will not return an error if the channelID is invalid.
      const response = (await this.command(
        new CommandChatLeave({
          ChatId: channelId,
          PatientId: patientId,
        }),
      )) as Response;

      if (response) {
        this.therapistLeftSuccessfully$.next();
      }
    } catch (e) {
      const error: ResponseError = (e as Response).error;
      if (error.Message.includes('CHAT_CHANNEL_NOT_PROVIDED')) {
        this.therapistLeaveFailed$.next('THERAPIST_LEAVE_FAILED');
      } else if (error.Message.includes('CHAT_PATIENT_ID_MISSING')) {
        this.therapistLeaveFailed$.next('THERAPIST_LEAVE_FAILED');
      } else {
        this.therapistLeaveFailed$.next('CHAT_THERAPIST_UNABLE_TO_LEAVE');
      }
    }
  }

  public async chatTherapistDischarge(channelId: string, patientId: string) {
    try {
      // Caution: This command will not return an error if the channelID is invalid.
      const response = (await this.command(
        new CommandChatDischarge({
          ChatId: channelId,
          PatientId: patientId,
        }),
      )) as Response;

      if (response) {
        this.dischargeSuccessful$.next();
      }
    } catch (e) {
      const error: ResponseError = (e as Response).error;
      if (error.Message.includes('CHAT_CHANNEL_NOT_PROVIDED')) {
        this.therapistLeaveFailed$.next('THERAPIST_DISCHARGE_FAILED');
      } else if (error.Message.includes('CHAT_PATIENT_ID_MISSING')) {
        this.therapistLeaveFailed$.next('THERAPIST_DISCHARGE_FAILED');
      } else {
        this.dischargeFailed$.next('Failed to discharge patient');
      }
    }
  }

  public markMessagesRead() {
    this.command(new CommandChatSetLastRead());
  }

  public loadMoreMessages() {
    this.command(new CommandChatMessages());
  }

  private setMessages(messages: ChatMessageModel[]) {
    const patientId = this.getPatientUserId();
    const filterPatientMsgs = messages.filter(m =>
      m.uuid === patientId && m?.meta?.fields?.uuid !== undefined
        ? m?.meta?.fields?.uuid.value !== 'thriveTherapeutic'
        : '',
    );
    const lastPatientMsg = filterPatientMsgs[filterPatientMsgs.length - 1];
    messages.map((message, k) => {
      // If we receive a message with no meta object, try and recover by creating one.
      if (!message?.meta) {
        message['meta'] = {};
      }

      messages[k] = this.processMeta(message, lastPatientMsg, messages, patientId);
    });

    // TODO - we should address the updating of the messages model in various places in this service.
    // Having to update the client messages model here is bad form. We should look to move all state management out
    // into something like redux.
    this.chatClient.messages = messages.slice();
    this.messages$.next(messages);
  }

  private setMessagesDiff(messages: ChatMessageModel[]) {
    const patientId = this.getPatientUserId();
    const filterPatientMsgs = messages.filter(m =>
      m.uuid === patientId && m?.meta?.fields?.uuid !== undefined
        ? m?.meta?.fields?.uuid.value !== 'thriveTherapeutic'
        : '',
    );
    const lastPatientMsg = filterPatientMsgs[filterPatientMsgs.length - 1];

    messages.map(message => {
      // If we receive a message with no meta object, try and recover by creating one.
      if (!message?.meta) {
        message['meta'] = {};
      }

      const existingId: number = this.chatClient.messages.findIndex((v): boolean => v.timeId === message.timeId);

      message = this.processMeta(message, lastPatientMsg, messages, patientId);
      if (existingId >= 0) {
        this.chatClient.messages[existingId] = message;
      } else {
        this.chatClient.messages.push(message);
      }
    });

    this.chatClient.messages.sort((a, b) => (a.timeId >= b.timeId ? 1 : -1));

    console.log('[SASHA] ::', this.chatClient.messages.length);
    this.messages$.next(this.chatClient.messages);
  }

  private processMeta(
    message: ChatMessageModel,
    lastPatientMsg: ChatMessageModel,
    messages: ChatMessageModel[],
    patientId: string,
  ): ChatMessageModel {
    if (this.getUser().id === message.uuid) {
      const channelActivity = this.chatClient.opponentsActivity.get(this.activeChannel$.value);
      const patientActivity = channelActivity?.find(a => a.userID === patientId);
      const patientLastMsgTime = lastPatientMsg ? new Date(Number(lastPatientMsg.timeId / BigInt(10000))) : null;
      const messageDate = message.timeId ? new Date(Number(message.timeId / BigInt(10000))) : null;

      if (patientActivity) {
        const patientLastSeenTime = this.convertEpochDate(patientActivity.Time);
        if (messageDate != null && (patientLastSeenTime >= messageDate || patientLastMsgTime >= messageDate)) {
          message.meta['hasBeenRead'] = true;
        } else {
          message.meta['hasBeenRead'] = false;
        }
      } else if (patientLastMsgTime) {
        if (messageDate != null && patientLastMsgTime >= messageDate) {
          message.meta['hasBeenRead'] = true;
        } else {
          message.meta['hasBeenRead'] = false;
        }
      }
    }

    if (message.meta?.fields !== undefined && message?.meta?.fields.ReadOnly !== undefined) {
      message.meta['readOnly'] = message?.meta?.fields.ReadOnly.kind.value;
    }

    message.meta['owner'] = this.getMessageOwner(message.uuid);
    message.meta['reply'] = this.getMessageReply(message, messages);
    message.meta['sent'] = this.getMessageSent(message);

    if (Object.keys(message.liked).length > 0) {
      message.meta['likedBy'] = this.getLikedBy(message.liked, patientId);
    }

    return message;
  }

  private convertEpochDate(epoch: BigInt): Date {
    const parse = parseInt(epoch.toString());
    const date = new Date(0);
    date.setUTCMilliseconds(parse / 10000);
    return date;
  }

  private getMessageSent(message: ChatMessageModel): boolean {
    let sentValue = true;
    if (message.meta?.fields !== undefined && message.meta?.fields.Temp !== undefined) {
      sentValue = !message?.meta?.fields.Temp.kind.value;
    }

    return !this.messageService.isOnline$.value ? sentValue : this.messageService.isOnline$.value;
  }

  private getMessageOwner(uuid: string): string {
    if (this.getUser().id === uuid) {
      return 'You';
    }

    const user = this.chatClient?.channelUsers?.filter(u => u.userId === uuid)[0];
    if (user?.role === 'therapist') {
      return 'Therapist';
    } else if (user?.role === 'patient') {
      return 'Patient';
    } else {
      return 'Therapist';
    }
  }

  private getLikedBy(liked: { [key: string]: string }, patientId: string): string {
    const userId = this.getUser().id;
    const therapists = this.activeTherapists$.value;
    const likedBy = [];

    for (const uuid of Object.keys(liked)) {
      const filteredTherapists = therapists.filter(u => u.userId === uuid);

      if (userId === uuid) {
        likedBy.push('You');
      }
      if (filteredTherapists.length > 0 && userId !== uuid) {
        likedBy.push('Therapist');
      }
      if (patientId === uuid) {
        likedBy.push('Patient');
      }
      if (userId !== uuid && filteredTherapists.length === -1 && patientId !== uuid) {
        likedBy.push('Therapist');
      }
    }

    const likedByString = likedBy.toString().replace(/,/g, ', ');

    if (likedBy.length === 2) {
      return likedByString.replace(',', ' and ');
    } else if (likedBy.length > 2) {
      return likedByString.replace(/,(?=[^,]+$)/, ' and');
    }

    return likedByString;
  }

  private getMessageReply(message: ChatMessageModel, allMessages: ChatMessageModel[]): ChatReplyModel {
    if (message.meta?.fields !== undefined && message.meta?.fields?.REPLY_ID !== undefined) {
      const replyId = message.meta?.fields?.REPLY_ID.kind.value;
      const replyText = message.meta?.fields?.REPLY_TEXT.kind.value;
      const replyUuid = message.meta?.fields?.REPLY_AUTHOR.kind.value;
      const replyAuthor = this.getMessageOwner(replyUuid);

      if (replyId && replyText && replyUuid && replyAuthor) {
        const original = allMessages.find(m => m.timeId === replyId);

        return {
          id: replyId,
          text: replyText,
          author: replyAuthor,
          uuid: replyUuid,
          deleted: original?.deleted || false,
        } as ChatReplyModel;
      }
    }
  }
}
