import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MessageService } from '@shared/services/message.service';
import { TimelineService } from '../timeline.service';

@Component({
  selector: 'app-add-cgi-moment',
  templateUrl: './add-cgi-moment.component.html',
  styleUrls: ['./add-cgi-moment.component.scss']
})
export class AddCgiMomentComponent {
  @ViewChild('dialog') dialog: any;
  cgiOptionList = [1, 2, 3, 4, 5, 6, 7];

  constructor(
    private dialogRef: MatDialogRef<AddCgiMomentComponent>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) private data: { timelineService: TimelineService, patientId: string },
    private router: Router
  ) { }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  public async addMomentButtonClicked(cgiSeverity: string, cgiImprovement: string): Promise<void> {
    const severity = Number(cgiSeverity);
    const improvement = Number(cgiImprovement);

    if (isNaN(severity) || isNaN(improvement) || severity < 1 || severity > 7 || improvement < 1 || improvement > 7) {
      this.messageService.showMessage('Please enter valid numbers between 1 and 7.');
      return;
    }

    const cgiData = {
      CGISeverity: severity,
      CGIImprovement: improvement
    };

    try {
      await this.dialog.showAsyncProgressForFunction(async () => {
        await this.data.timelineService.addCgiMoment(this.data.patientId, cgiData);
        this.data.timelineService.updateTimelineWithFilterOptions();
      });
      this.dialogRef.close();
      // await this.router.navigate([`/therapist/assigned-patients/patient-profile/${this.data.patientId}`]);
    } catch (err) {
      console.error(err);
      this.messageService.showMessage('Error: ' + err.toString());
    }
  }
}
