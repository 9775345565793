import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { concatMap, debounceTime, tap } from 'rxjs/operators';

import { Moment } from './moment/moment';
import { CallMoment } from './moment/call-moment/call-moment';
import { ChatMoment } from './moment/chat-moment/chat-moment';
import { ChatType } from './moment/chat-moment/chat-type.enum';
import { HelperService } from '@shared/services/helper.service';
import { PatientTimelineService } from '@backend-client/services';
import {
  DashboardTubMoment,
  TubMomentDescriptionResponseDto,
  TubMomentDescriptionAuditData,
  TubCgiCreationRequestBody,
} from '@backend-client/models';
import { TimelineFilterOptions } from './timeline-filters/timeline-filter-options';
import { MomentType } from './moment/moment-type';
import { ETagged, toTagged } from '@classes/tagged-model';
import { AuditEventMoment } from './moment/audit-event-moment/audit-event-moment';
import { FileMoment } from './moment/file-moment/file-moment';
import { NoteMoment } from './moment/note-moment/note-moment';
import { GoogleDriveFileMoment } from './moment/google-drive-file-moment/google-drive-file-moment';
import { RiskAssessmentEventMoment } from '../patient-timeline/moment/risk-assessment-event-moment/risk-assessment-event-moment';
import { DischargeMoment } from '../patient-timeline/moment/discharge-moment/discharge-moment';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { CgiScoreMoment } from '../patient-timeline/moment/cgi-score/cgi-score-moment';

@Injectable()
export class TimelineService {
  public static readonly MOMENTS_PER_REQUEST = 15;

  public displayedMoments: Moment[];

  public readonly filterOptions$ = new Subject<TimelineFilterOptions>(); // REVIEW: Could this be a BehaviourSubject instead?
  private currentFilterOptions: TimelineFilterOptions = null;
  private isAssessmentMomentsEnabledFeatureFlag = false;

  constructor(
    private helperService: HelperService,
    private patientTimelineService: PatientTimelineService,
    private httpClient: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {
    this.featureFlagService.featureFlags$.subscribe(config => {
      this.isAssessmentMomentsEnabledFeatureFlag = config.isAssessmentMomentsEnabled;
    });
  }

  public async getLatestNoteMoment(patientId: string): Promise<DashboardTubMoment[]> {
    return await this.patientTimelineService
      .PatientsMomentsGetPatientMoments({
        patientId,
        types: ['note'],
        startFrom: Date.now(),
        limit: 1,
      })
      .toPromise();
  }

  public async addNoteMoment(patientId: string, note: string) {
    await this.patientTimelineService
      .PatientsMomentsCreatePatientNote({
        patientId,
        createPatientNoteRequestBody: { text: note },
      })
      .toPromise();
  }

  public async addCgiMoment(patientId: string, cgiData: TubCgiCreationRequestBody) {
    await this.patientTimelineService
      .PatientsMomentsCreatePatientCgiMoment({ patientId, cgiBody: cgiData })
      .toPromise();
  }

  public async addFileMoment(patientId: string, file: File, type: MomentType): Promise<void> {
    // get document policy to upload the file
    const policy = await this.patientTimelineService
      .PatientsMomentsGetPatientFileUploadPolicyDocument({
        patientId,
        type,
        fileName: file.name,
      })
      .toPromise();

    const formData = new FormData();

    for (const formDataParam in policy.formData) {
      if (policy.formData.hasOwnProperty(formDataParam)) {
        formData.append(formDataParam, policy.formData[formDataParam]);
      }
    }

    formData.append('file', file);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    // necessary evil for CSP - regex which replaces  https://storage.googleapis.com/X/ with https://X.storage.googleapis.com/ in a string
    // which brings the bucket name into the domain so it can be whitelisted on CSP
    policy.url = policy.url.replace(
      /(https:\/\/storage\.googleapis\.com\/)([a-zA-Z0-1-]+)(\/|$)/,
      'https://$2.storage.googleapis.com/',
    );

    await this.httpClient.post(policy.url, formData, { headers }).toPromise();

    // REVIEW: Wait 8 seconds to ensure the cloud function has run on the uploaded file, and created the necessary FileMoment
    //         Task made to revisit : https://app.clubhouse.io/thrivesoft/story/1019/
    await this.helperService.delay(8000);
  }

  /**
   * Determines whether a chat is "active" or not.
   * An active chat is one that has a start chat moment, but no corresponding end chat moment
   * @param startChatMoment  The start chat moment to test
   */
  public isActiveChat(startChatMoment: ChatMoment): boolean {
    const hasCorrespondingChatEndMoment = this.displayedMoments.some(moment => {
      if (moment instanceof ChatMoment) {
        return moment.chatSessionId === startChatMoment.chatSessionId && moment.chatType === ChatType.EndChat;
      }
    });
    return !hasCorrespondingChatEndMoment;
  }

  public createServerFetchObservable(
    filterOptions: TimelineFilterOptions,
    needMoreMoments$: Observable<void>,
    patientId: string,
  ): Observable<Moment[]> {
    let paginationCursor: string;
    return needMoreMoments$.pipe(
      // End of scroll can be trigger happy, so add in debounce
      debounceTime(500),
      concatMap(() => {
        return this.fetchAdditionalMoments(filterOptions, patientId, paginationCursor);
      }),
      tap((moments: Moment[]) => {
        if (moments.length > 0) {
          paginationCursor = moments[moments.length - 1].id;
        }
      }),
    );
  }

  public updateTimelineWithFilterOptions(filterOptions?: TimelineFilterOptions): void {
    if (filterOptions) {
      this.filterOptions$.next(filterOptions);
      this.currentFilterOptions = filterOptions;
    } else {
      this.filterOptions$.next(this.currentFilterOptions);
    }
  }

  public async getPatientMomentDescription(
    patientId: string,
    momentId: string,
  ): Promise<ETagged<TubMomentDescriptionResponseDto>> {
    return toTagged<TubMomentDescriptionResponseDto>(
      await this.patientTimelineService
        .PatientsMomentsGetDriveMomentDescriptionResponse({ patientId, momentId })
        .toPromise(),
    );
  }

  public async updatePatientMomentDescription(
    patientId: string,
    momentId: string,
    description: string,
    eTag: string,
  ): Promise<void> {
    return await this.patientTimelineService
      .PatientsMomentsUpdateDriveMomentDescription({
        patientId,
        momentId,
        descriptionDTO: eTag ? { ETag: eTag, description: description } : { description: description },
      })
      .toPromise();
  }

  public async getPatientMomentDescriptionAuditHistory(
    patientId: string,
    momentId: string,
  ): Promise<TubMomentDescriptionAuditData> {
    return await this.patientTimelineService
      .PatientsMomentsGetDriveMomentDescriptionAudit({ patientId, momentId })
      .toPromise();
  }

  private async fetchAdditionalMoments(
    filterOptions: TimelineFilterOptions,
    patientId: string,
    paginationCursor?: string,
  ): Promise<Moment[]> {
    console.log('Fetching additional moments from', paginationCursor, filterOptions.types.call);

    // Convert the filter option types into an array of type name strings
    const filteredTypes = this.getTypesFromFilterOptions(filterOptions);

    // retrieve the tub moments from the server
    const tubMoments: DashboardTubMoment[] = await this.patientTimelineService
      .PatientsMomentsGetPatientMoments({
        patientId,
        types: filteredTypes,
        startFrom: filterOptions.date.getTime(),
        paginationCursor,
        limit: TimelineService.MOMENTS_PER_REQUEST,
      })
      .toPromise();

    // create the client-side moments that represent the tub moments
    return tubMoments.map(tubMoment => this.convertTubMomentToMoment(tubMoment));
  }

  private convertTubMomentToMoment(tubMoment: DashboardTubMoment): Moment {
    const momentType = tubMoment.type as MomentType;
    const momentData = tubMoment.data as any;
    try {
      switch (momentType) {
        case MomentType.Call:
          return new CallMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            fileName: momentData.fileName,
            author: tubMoment.author,
          });
        case MomentType.Chat:
          return new ChatMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            chatSessionId: momentData.chatRoomId,
            chatSessionArchive: null,
            chatType: momentData.type === 'chat-start' ? ChatType.StartChat : ChatType.EndChat,
            pubNub: momentData.pubNub,
            author: tubMoment.author,
          });
        case MomentType.Event:
          if (this.isAssessmentMomentsEnabledFeatureFlag) {
            if (momentData.type === 'RiskFactorResults') {
              return new RiskAssessmentEventMoment({
                timestamp: new Date(tubMoment.ts),
                id: tubMoment.id,
                author: tubMoment.author,
                data: momentData,
              });
            } else {
              return new AuditEventMoment({
                timestamp: new Date(tubMoment.ts),
                id: tubMoment.id,
                eventTitle: momentData.type,
                author: tubMoment.author,
              });
            }
          } else {
            return new AuditEventMoment({
              timestamp: new Date(tubMoment.ts),
              id: tubMoment.id,
              eventTitle: momentData.type,
              author: tubMoment.author,
            });
          }
        case MomentType.File:
          return new FileMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            fileName: momentData.fileName,
            author: tubMoment.author,
          });
        case MomentType.Note:
          return new NoteMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            note: momentData.note,
            author: tubMoment.author,
          });
        case MomentType.GoogleFile:
          return new GoogleDriveFileMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            googleDriveFileId: momentData.googleDriveFileId,
            googleDriveFolderId: momentData.googleDriveFolderId,
            fileName: momentData.fileName,
            contentType: momentData.contentType,
            description: momentData.description?.text,
          });
        case MomentType.CgiScore:
          return new CgiScoreMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            cgiSeverity: momentData.cgiSeverity,
            cgiImprovement: momentData.cgiImprovement,
          });
        case MomentType.Discharge:
          return new DischargeMoment({
            timestamp: new Date(tubMoment.ts),
            id: tubMoment.id,
            createdByUserId: 0,
            author: tubMoment.author,
            therapistName: momentData.therapistName ? momentData.therapistName : 'Unknown',
          });
      }
    } catch (err) {
      console.error('could not convert tubmoment to clientside moment', { momentData, err });
      throw err;
    }
  }

  private getTypesFromFilterOptions(filterOptions: TimelineFilterOptions): MomentType[] {
    const types = [];
    for (const typeName in filterOptions.types) {
      // Capitalise - as google-file cannot be hyphenated on the filter options
      const momentTypeName = typeName.charAt(0).toUpperCase() + typeName.slice(1);

      if (filterOptions.types[typeName]) {
        types.push(MomentType[momentTypeName]);
      }
    }
    return types;
  }
}
