import { Component } from '@angular/core';
import { RiskAssessmentEventMoment, RiskAssessmentIntroText } from './risk-assessment-event-moment';

@Component({
  selector: 'app-risk-assessment-event-moment',
  templateUrl: './risk-assessment-event-moment.component.html',
  styleUrls: ['./risk-assessment-event-moment.component.scss'],
})
export class RiskAssessmentEventMomentComponent {
  RiskAssessmentIntroText = RiskAssessmentIntroText;

  momentData: RiskAssessmentEventMoment;
}
