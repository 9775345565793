// go-chat-duplication
import { Component, ViewEncapsulation } from '@angular/core';

import { GoMessageNotificationButtonService } from '@layout/main-nav/go-message-notification-button/go-message-notification-button.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { GoClientChatSession } from '@layout/main-nav/go-message-notification-button/go-client-chat-session-model';

@Component({
  selector: 'app-go-message-notification-button',
  templateUrl: './go-message-notification-button.component.html',
  styleUrls: ['./go-message-notification-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GoMessageNotificationButtonComponent {
  public chatSessions$: BehaviorSubject<GoClientChatSession[]> = this.messageNotificationButtonService.chatSessions$;
  public totalChatNotifications$: Subject<number> = this.messageNotificationButtonService.totalChatNotifications$;

  constructor(private messageNotificationButtonService: GoMessageNotificationButtonService) {}
}
