import { StrictHttpResponse as __StrictHttpResponse } from '@backend-client/strict-http-response';

export type ETagged<T> = T & { ETag?: string };

export function toTagged<T>(response: __StrictHttpResponse<T>): ETagged<T> {
  return Object.assign(response.body, {
    ETag: response.headers.get('ETag')
  });
}

export function fromTagged<T>(data: ETagged<T>): { data: T, ETag: string } {
  const clone = Object.assign({}, data);
  delete clone.ETag;

  return {
    data: clone,
    ETag: data.ETag
  };
}
