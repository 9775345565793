import { Moment } from '../moment';
import { MomentType } from '../moment-type';
export class CgiScoreMoment extends Moment {
  readonly typeName = MomentType.CgiScore;
  public cgiSeverity: number;
  public cgiImprovement: number;

  constructor(options: {
    id: string;
    timestamp: Date;
    createdByUserId: number;
    author: string;
    cgiSeverity: number;
    cgiImprovement: number;
  }) {
    super(options.id, options.timestamp, options.author);
    this.cgiSeverity = options.cgiSeverity;
    this.cgiImprovement = options.cgiImprovement;
  }
}
