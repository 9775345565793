import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogComponent } from '@shared/components/dialog/dialog.component';

@Component({
  selector: 'app-expand-note-moment-dialog',
  templateUrl: './expand-note-moment-dialog.component.html',
  styleUrls: ['./expand-note-moment-dialog.component.scss']
})
export class ExpandNoteMomentDialogComponent {

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; subtitle: string; note: string; },
              private dialogRef: MatDialogRef<ExpandNoteMomentDialogComponent>) { }

  public cancelButtonClicked(): void {
    this.dialogRef.close();
  }

}
