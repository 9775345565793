import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { GoogleDriveService } from '@shared/services/google-drive/google-drive.service';
import { GoogleDrivePreviewService } from '@shared/services/google-drive/google-drive-preview.service';
import { GoogleDriveDownloadService } from '@shared/services/google-drive/google-drive-download.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { GoogleDriveActions } from '@shared/services/google-drive/google-drive-actions.enum';
import { MomentType } from '../moment-type';
import { GoogleDriveFileMoment } from './google-drive-file-moment';
import { TimelineService } from '../../timeline.service';
import { AddEditGoogleFileMomentDescriptionComponent } from './add-edit-google-file-moment-description/add-edit-google-file-moment-description.component';
import { GoogleFileMomentDescriptionHistoryComponent } from './google-file-moment-description-history/google-file-moment-description-history.component';

@Component({
  selector: 'app-google-drive-file-moment',
  templateUrl: './google-drive-file-moment.component.html',
  styleUrls: [ './google-drive-file-moment.component.scss' ]
})
export class GoogleDriveFileMomentComponent {

  public momentData: GoogleDriveFileMoment;
  public momentType = MomentType;
  public showDownloadButton = this.googleDriveDownloadService.showDownloadButton;

  public featureFlags$ = this.featureFlagService.featureFlags$;

  constructor(
    private featureFlagService: FeatureFlagService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private timelineService: TimelineService,
    private googleDrivePreviewService: GoogleDrivePreviewService,
    private googleDriveDownloadService: GoogleDriveDownloadService,
    private googleDriveService: GoogleDriveService
  ) {
  }

  public addEditDescription(description?: string): void {
    const data = { timelineService: this.timelineService, patientId: this.activatedRoute.snapshot.params.patientId, momentId: this.momentData.id, description};
    this.dialogService.openDialog(AddEditGoogleFileMomentDescriptionComponent, data, { large: true });
  }

  public fetchAuditHistory(): void {
    const data = { timelineService: this.timelineService, patientId: this.activatedRoute.snapshot.params.patientId, momentId: this.momentData.id};
    this.dialogService.openDialog(GoogleFileMomentDescriptionHistoryComponent, data, { large: true });
  }

  /* Callback after api.js is loaded. */
  public gapiLibraryLoaded(): void {
    gapi.load('client', this.initializeGapiLibrary.bind(this));
  }

  /* Callback after the API client is loaded. Calls service to Load the discovery doc & initialize the API. */
  public initializeGapiLibrary(): void {
    this.googleDriveService.initGoogleDriveApi(GoogleDriveActions.Download);
  }

  public previewMoment(): void {
    this.googleDriveService.gisLibraryLoaded(GoogleDriveActions.Preview);
    this.googleDriveService.initAccount(GoogleDriveActions.Preview, this.googleDriveService?.googleUserEmail);
    this.googleDrivePreviewService.googleFileMoment = this.momentData;
  }

  public downloadMoment(): void {
    this.googleDriveService.gisLibraryLoaded(GoogleDriveActions.Download);
    this.googleDriveDownloadService.driveFileMoment = this.momentData;
    this.gapiLibraryLoaded();
    this.googleDriveService.promptRequestToken();
  }
}
