import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasExpandableContent'
})
export class HasExpandableContentPipe implements PipeTransform {

  transform(content: HTMLElement): boolean {
    let containsContentNodes = false;
    for (let i = 0; i < content.childNodes.length; i++) {
      if (content.childNodes.item(i).nodeType !== 8) {
        containsContentNodes = true;
        break;
      }
    }
    return containsContentNodes;
  }

}
