import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MomentDisplayService } from './moment-display.service';

@Directive({
  selector: '[appCreateMoment]'
})
export class CreateMomentDirective implements OnInit {

  @Input() moment;

  constructor(
    private momentDisplayService: MomentDisplayService,
    public viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    const displayMomentComponent = this.momentDisplayService.getMomentComponentForData(this.moment);
    console.assert(displayMomentComponent != null,
      'MomentComponent should be findable - there is an unknown moment data type in the codebase. Use @MomentDisplay to register.');
    const componentRef = this.viewContainerRef.createComponent(displayMomentComponent);
    (componentRef.instance as any).momentData = this.moment;
  }

}
