import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MainNavComponent } from './main-nav/main-nav.component';
import { FeedbackFormComponent } from './main-nav/feedback-form/feedback-form.component';
import { MessageNotificationButtonComponent } from './main-nav/message-notification-button/message-notification-button.component';
import { TotalUnreadMessagesPipe } from './main-nav/message-notification-button/total-unread-messages.pipe';
import { PrimaryNotificationButtonComponent } from './main-nav/primary-notification-button/primary-notification-button.component';
import { PrimaryNotificationComponent } from './main-nav/primary-notification-button/primary-notification/primary-notification.component';
import { ChatRequestNotificationDirective } from './main-nav/primary-notification-button/notifications/chat-request-notification.directive';
import { LeadStatusChangeNotificationDirective } from './main-nav/primary-notification-button/notifications/lead-status-change-notification.directive';
import { AssignedPatientNotificationDirective } from './main-nav/primary-notification-button/notifications/assigned-patient-notification.directive';
import { SharedModule } from '@shared/shared.module';
import { AuthenticationModule } from '@authentication/authentication.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavLinkComponent } from './main-nav/nav-link/nav-link.component';
import { ContainsActiveLinkPipe } from './main-nav/nav-link/contains-active-link.pipe';
import { HomeComponent } from './home/home.component';
import { FailedMessagesButtonComponent } from './main-nav/failed-messages-button/failed-messages-button/failed-messages-button.component';
import { LogoutConfirmationDialogComponent } from './main-nav/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { LegalDocsComponent } from '../legaldocs/legal-docs/legal-docs.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
// go-chat-duplication start
import { GoMessageNotificationButtonComponent } from '@layout/main-nav/go-message-notification-button/go-message-notification-button.component';
import { GoChatRequestNotificationDirective } from '@layout/main-nav/go-primary-notification-button/go-notifications/go-chat-request-notification.directive';
import { GoChatUpgradeNotificationDirective } from './main-nav/go-primary-notification-button/go-notifications/go-chat-upgrade-notification.directive';
import {
  GoLeadStatusChangeNotificationDirective
} from '@layout/main-nav/go-primary-notification-button/go-notifications/go-lead-status-change-notification.directive';
import {
  GoPrimaryNotificationButtonComponent
} from '@layout/main-nav/go-primary-notification-button/go-primary-notification-button.component';
import {
  GoPrimaryNotificationComponent
} from '@layout/main-nav/go-primary-notification-button/go-primary-notification/go-primary-notification.component';
import { GoTotalUnreadMessagesPipe } from '@layout/main-nav/go-message-notification-button/go-total-unread-messages.pipe';
import { GoAssignedPatientNotificationDirective } from './main-nav/go-primary-notification-button/go-notifications/go-assigned-patient-notification.directive';
import { OfficeHoursComponent } from './main-nav/office-hours/office-hours.component';
// go-chat-duplication end

@NgModule({
  declarations: [
    MainNavComponent,
    FeedbackFormComponent,
    MessageNotificationButtonComponent,
    TotalUnreadMessagesPipe,
    PrimaryNotificationButtonComponent,
    PrimaryNotificationComponent,
    ChatRequestNotificationDirective,
    LeadStatusChangeNotificationDirective,
    AssignedPatientNotificationDirective,
    PageNotFoundComponent,
    HomeComponent,
    NavLinkComponent,
    ContainsActiveLinkPipe,
    FailedMessagesButtonComponent,
    LogoutConfirmationDialogComponent,
    LegalDocsComponent,
    UnauthorisedComponent,
    // go-chat-duplication start
    GoMessageNotificationButtonComponent,
    GoLeadStatusChangeNotificationDirective,
    GoPrimaryNotificationButtonComponent,
    GoPrimaryNotificationComponent,
    GoChatRequestNotificationDirective,
    GoChatUpgradeNotificationDirective,
    GoTotalUnreadMessagesPipe,
    GoAssignedPatientNotificationDirective,
    OfficeHoursComponent
    // go-chat-duplication end
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AuthenticationModule
  ],
  exports: [
    MainNavComponent
  ]
})
export class LayoutModule { }
