import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ChatType } from './chat-type.enum';
import { ChatMoment } from './chat-moment';
import { TimelineService } from '../../timeline.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { ChatLogDialogComponent } from '../../chat-log-dialog/chat-log-dialog.component';

@Component({
  selector: 'app-chat-moment',
  templateUrl: './chat-moment.component.html',
  styleUrls: [ './chat-moment.component.scss' ]
})
export class ChatMomentComponent implements OnInit {

  ChatType = ChatType;
  momentData: ChatMoment;
  isActiveChat: boolean;
  
  constructor(
    private timelineService: TimelineService,
    private router: Router,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    ) {}

  ngOnInit() {
    if (this.momentData.chatType === ChatType.StartChat) {
      this.isActiveChat = this.timelineService.isActiveChat(this.momentData);
    }
  }

  public async goToActiveChatClicked(chatRoomId: string): Promise<void> {
    try {
      await this.router.navigate([ `/therapist/chat/${chatRoomId}` ]);
    } catch (err) {
      console.error(err);
    }
  }

  //go-chat-duplication start
  public async goToActiveGoChatClicked(chatRoomId: string): Promise<void> {
    try {
      await this.router.navigate([ `/therapist/go-chat/${chatRoomId}` ]);
    } catch (err) {
      console.error(err);
    }
  }
  // go-chat-duplication end

  public viewChatLogClicked(chatMoment: ChatMoment) {
    const data = {
      chatMoment,
      patientId: this.activatedRoute.snapshot.params.patientId
    };
    this.dialogService.openDialog(ChatLogDialogComponent, data);
  }
}
