import { Moment } from '../moment';
import { MomentType } from '../moment-type';

export class NoteMoment extends Moment {

  public note: string;
  public createdByUserId: number;
  readonly typeName = MomentType.Note;

  constructor(options: { id: string, timestamp: Date, note: string, createdByUserId: number, author: string }) {
    super(options.id, options.timestamp, options.author);
    this.note = options.note;
    this.createdByUserId = options.createdByUserId;
  }
}
