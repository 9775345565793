<div *ngIf="linkConnectionType === MomentLinkConnectionType.EndLink" class="link end-link">
  <ng-container *ngTemplateOutlet="linkBlipLast"></ng-container>
</div>
<div *ngIf="isInLinkGroup && linkConnectionType === MomentLinkConnectionType.None" class="link mid-link">
  <ng-container *ngTemplateOutlet="linkBlipFirst"></ng-container>
  <ng-container *ngTemplateOutlet="linkBlipLast"></ng-container>
</div>
<div *ngIf="linkConnectionType === MomentLinkConnectionType.StartLink" class="link start-link">
  <ng-container *ngTemplateOutlet="linkBlipFirst"></ng-container>
</div>


<ng-template #linkBlipFirst>
  <mat-icon *ngIf="isFirst" class="first">fiber_manual_record</mat-icon>
</ng-template>
<ng-template #linkBlipLast>
  <mat-icon *ngIf="isLast" class="last">fiber_manual_record</mat-icon>
</ng-template>
