<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  <div *ngIf="mediaObserver.isActive('gt-xs')" class="icon-container" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon *ngIf="icon" class="icon" [ngStyle]="{color: colour || 'black'}">{{icon}}</mat-icon>
    <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon" [ngStyle]="{color: colour || undefined}"></mat-icon>
  </div>
  <!--It's a bit nasty calling | hasExpandableContent twice, but I can't see another way-->
  <mat-expansion-panel [hideToggle]="!(content | hasExpandableContent)" [disabled]="!(content | hasExpandableContent)">
    <mat-expansion-panel-header>
      <mat-panel-title fxFlex="53" fxFlex.gt-md="56" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <ng-container *ngIf="!mediaObserver.isActive('gt-xs')">
          <mat-icon [ngStyle]="{color: colour || 'black'}">{{icon}}</mat-icon>
        </ng-container>
        <span>{{title}}</span>

        <button *ngIf="expandOption" mat-icon-button color="primary" matTooltip="Open note in dialog" (click)="expandFunc.emit(); $event.stopPropagation();">
          <mat-icon>expand_content</mat-icon>
        </button>
      </mat-panel-title>

      <mat-panel-description fxFlex fxLayout="row" fxLayoutAlign="end center">
        <span [matTooltip]="momentData.author">{{momentData.author}}</span> | {{momentData.timestamp | convertDateToTime}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div #content>
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</div>


