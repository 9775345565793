<app-dialog #dialog
  (cancel)="cancelButtonClicked()"
  cancelButtonText="Close"
  title="Chat archive">
  <div id="chat-log-container" fxLayout="column">
    <ng-container *ngFor="let chatMessage of chatMessages | keyvalue">
      <mat-divider></mat-divider>

      <div class="chat-message-container">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <b>{{chatMessage.value.username | displayUserName: '':chatMessage.value.from}}</b>

          <span class="date">
            {{chatMessage.value.time | convertUnixTimeToLocaleString}}
          </span>
        </div>

        <span [ngClass]="{'system-message': !chatMessage.value.username}">{{chatMessage.value.msg}}</span>
      </div>
    </ng-container>

    <mat-divider></mat-divider>
  </div>
</app-dialog>
